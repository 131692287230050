<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Nueva Aseguradora</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <form @submit.prevent="guardarAseguradora">
            <div class="card-header">
              <div class="col-sm-5">
                <button type="submit"
                 class="btn bg-custom-green mr-2 rounded"
                 :disabled="buttonDisabled">
                 Guardar
                </button>
                <router-link :to="{ name: 'aseguradora-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">Cliente:
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect
                        v-model="clienteSelected"
                        :options="clientes"
                        placeholder=""
                        label="nombre"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="caret" v-if="!(clienteSelected===null)"></span>
                        <span slot="noResult">Sin Resultados</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 1:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto1"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 2:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto2"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                <div class="row mt-2 justify-content-center">
                  <div class="col-sm-6 pr-0 text-left">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">
                        No imprime Diagnóstico
                      </label>
                      <div class="icon-container ml-2">
                        <svg @mouseover="hover = true" @mouseleave="hover = false" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0,0,256,256"
                            style="fill:#FFFFFF;">
                            <g fill="#e91414" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
                          </svg>
                        <div class="info-message" :class="{ 'visible': hover }">Esto solo afecta al imprimir en internacion/C E en receta</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Nit:
                      </label>
                    </div>
                    <div class="col-sm-5">
                      <input
                        type="text"
                        :value="nit"
                        class="form-control text-center"
                        autocomplete="off"
                        disabled
                      >
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion" width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion" width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais"
                        v-model="celular1"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular1">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected2"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais2">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais2"
                        v-model="celular2"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular2"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fin Tabla -->
            </div>
          </form>
        </div>
        <b-tabs class="nav-tabs custom-tabs">
          <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :title="tab.title"
              :active="tab.active"
              :disabled="tab.disabled"
              >
              <div v-if="index === 0">
                <form @submit.prevent="agregarModificarItem">
                  <div class="row mb-2">
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-3 text-center pr-0">
                          <label class="col-form-label">
                            Servicio:
                          </label>
                        </div>
                        <div class="col-sm-9 px-0">
                          <multiselect
                            v-model="serviciosSelected"
                            :options="servicios"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="row">
                        <div class="col-sm-4 text-center pr-0">
                          <label class="col-form-label">
                            Categoria:
                          </label>
                        </div>
                        <div class="col-sm-8 px-1">
                          <multiselect
                            v-model="categoriaSelected"
                            :options="categoriaAseguradoras"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1" v-if="serviciosSelected && textButtonForm === 'Agregar'">
                      <div class="row">
                        <div class="col-sm-12">
                          <label class="col-form-label">
                            P1: {{ serviciosSelected.precio_1 | numeral('0,0.00') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 px-0">
                      <div class="row">
                        <div class="col-sm-5 text-right pr-0">
                          <label class="col-form-label">CoaSeguro:</label>
                        </div>
                        <div class="col-sm-7 px-1">
                           <vue-numeric class="form-control"
                            v-model="coaseguro"
                            separator=","
                            :precision="2"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 px-0">
                      <div class="row">
                        <div class="col-sm-5 text-right pr-0">
                          <label class="col-form-label">Seguro:</label>
                        </div>
                        <div class="col-sm-7 px-1">
                          <vue-numeric class="form-control"
                            v-model="seguro"
                            separator=","
                            :precision="2"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 text-center">
                      <button type="submit" class="btn btn-warning">
                        {{textButtonForm}}
                      </button>
                    </div>
                  </div>
                </form>
                <!-- Tabla -->
                <b-table
                  class="table table-hover"
                  :fields="fields"
                  :items="listaTabla"
                >
                  <template #cell(numero)="data">
                    {{data.index+1}}
                  </template>
                  <template #cell(editar)="items">
                    <i v-if="items.item.deleted === 0"
                      @click="onClickEditarIcon(items.item)"
                      class="nav-icon text-success i-Pen-5 font-weight-bold"
                      style="cursor: pointer"
                    ></i>
                  </template>
                  <template #cell(eliminar)="items">
                    <i v-if="items.item.deleted === 0"
                      @click="[onClickEliminarIcon(items), items.item.estado = false]"
                      class="nav-icon text-danger i-Close-Window font-weight-bold"
                      style="cursor: pointer"
                    ></i>
                  </template>
                  <template #cell(estado)="data">
                    <label class="switch switch-success mr-3">
                      <input
                        type="checkbox"
                        v-model="data.item.estado"
                        :disabled="data.item.deleted===1"
                        @change="modificarFila(data)"
                      >
                      <span class="slider"></span>
                    </label>
                  </template>
                  <template #cell(coa_seguro)="data">
                    {{data.item.coa_seguro | numeral('0,0.00')}}
                  </template>
                  <template #cell(seguro)="data">
                    {{data.item.seguro | numeral('0,0.00')}}
                  </template>
                  <template #cell(total)="data">
                    {{data.item.total | numeral('0,0.00')}}
                  </template>
                </b-table>
              </div>
              <div v-if="index === 1">
                <div v-if="isArchivoActivo">
                  <div class="row">
                    <div class="col-sm-1 mr-3">
                      <button
                    class="btn btn-secundary"
                    :class="{ 'active': isArchivoActivo, 'inactive': !isArchivoActivo }"
                    @click="toggleArchivoActivo"
                  >
                    Regresar
                  </button>
                  </div>
                  <div class="col-sm-3">
                    <button
                      class="btn green"
                      :disabled="isButtonDisabled"
                    >
                      Seleccionar Archivo
                    </button>
                  </div>
                </div>
                <hr style="border: 1px solid black; width: 100%; margin: 20px auto;">
                <div class="row">
                  <div class="col-sm-8">
                    <button
                    class="btn btn-secundary"
                  >
                    Descargar plantilla
                  </button>
                  </div>
                  <div class="col-sm-4 text-right">
                    <a>
                      Descargar Referencia <img src="../../../assets/images/e32.png" style="cursor: pointer" title="formato excel">

                    </a>
                  </div>
                </div>
              </div>
                <div v-else>
                <form @submit.prevent="agregarModificarItemProducto">
                  <div class="row mb-2">
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-3 text-center pr-0">
                          <label class="col-form-label">
                            Producto:
                          </label>
                        </div>
                        <div class="col-sm-9 px-0">
                          <multiselect
                            v-model="productoSelected"
                            :options="productos"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1" v-if="productoSelected && textButtonFormProd=== 'AgregarProducto'">
                      <div class="row">
                        <div class="col-sm-12">
                          <label class="col-form-label">
                            P1: {{ productoSelected | numeral('0,0.00') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 px-0">
                      <div class="row">
                        <div class="col-sm-5 text-right pr-0">
                          <label class="col-form-label">CoaSeguro:</label>
                        </div>
                        <div class="col-sm-7 px-1">
                          <vue-numeric class="form-control"
                            v-model="coaseguro"
                            separator=","
                            :precision="2"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 px-0">
                      <div class="row">
                        <div class="col-sm-5 text-right pr-0">
                          <label class="col-form-label">Seguro:</label>
                        </div>
                        <div class="col-sm-7 px-1">
                          <vue-numeric class="form-control"
                            v-model="seguro"
                            separator=","
                            :precision="2"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 text-center">
                      <button type="submit" class="btn btn-warning">
                        {{textButtonFormProd}}
                      </button>
                    </div>
                    <div class="col-sm-2 text-center">
                      <button
                      class="btn btn-info"
                      :class="{ 'active': isArchivoActivo, 'inactive': !isArchivoActivo }"
                      @click="toggleArchivoActivo"
                    >
                      Importar
                    </button>
                    </div>
                  </div>
                </form>
                <!-- Tabla -->
                <b-table
                  class="table table-hover"
                  :fields="fieldsProducto"
                  :items="listaTablaProductos"
                >
                  <template #cell(numero)="data">
                    {{data.index+1}}
                  </template>
                  <template #cell(editar)="items">
                    <i v-if="items.item.deleted === 0"
                      @click="onClickEditarIcon(items.item)"
                      class="nav-icon text-success i-Pen-5 font-weight-bold"
                      style="cursor: pointer"
                    ></i>
                  </template>
                  <template #cell(eliminar)="items">
                    <i v-if="items.item.deleted === 0"
                      @click="[onClickEliminarIcon(items), items.item.estado = false]"
                      class="nav-icon text-danger i-Close-Window font-weight-bold"
                      style="cursor: pointer"
                    ></i>
                  </template>
                  <template #cell(estado)="data">
                    <label class="switch switch-success mr-3">
                      <input
                        type="checkbox"
                        v-model="data.item.estado"
                        :disabled="data.item.deleted===1"
                        @change="modificarFila(data)"
                      >
                      <span class="slider"></span>
                    </label>
                  </template>
                  <template #cell(coa_seguro)="data">
                    {{data.item.coa_seguro | numeral('0,0.00')}}
                  </template>
                  <template #cell(seguro)="data">
                    {{data.item.seguro | numeral('0,0.00')}}
                  </template>
                  <template #cell(total)="data">
                    {{data.item.total | numeral('0,0.00')}}
                  </template>
                </b-table>
              </div>
              </div>
              <div v-if="index === 2">
                <b-table
                class="table table-hover"
                :fields="fieldsHabitacion"
                :items="datosHabitacion"
              >
              <template #cell(seguro)>
                <input type="text">
              </template>
              <template #cell(estado)="data">
                <label class="switch switch-success mr-3">
                  <input
                    type="checkbox"
                    v-model="data.item.estado"
                    :disabled="data.item.deleted===1"
                    @change="modificarFila(data)"
                  >
                  <span class="slider"></span>
                </label>
              </template>
                </b-table>
              </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'create',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      isLoading: true,
      contacto1: '',
      contacto2: '',
      codigoPais: '',
      codigoPais2: '',
      paisSelected: null,
      paisSelected2: null,
      paises: [],
      clienteSelected: null,
      clientes: [],
      serviciosSelected: null,
      servicios: [],
      celular1: '',
      celular2: '',
      coaseguro: 0,
      seguro: 0,
      fields: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'id', label: 'ID', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'servicio', label: 'Servicio', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'categoria', label: 'Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'coa_seguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      listaTabla: [],
      situaciones: null,
      buttonDisabled: false,
      categoriaSelected: null,
      categoriaAseguradoras: [],
      modoEdicionTabla: false,
      textButtonForm: 'Agregar',
      selectedId: null,
      selectedCategoryId: null,
      hover: false,
      productoSelected: null,
      productos: [],
      fieldsProducto: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'codigo', label: 'Codigo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'producto', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'subgrupo', label: 'Subgrupo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'coa_seguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      fieldsHabitacion: [
        {
          key: 'numero', label: 'N°', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'id', label: 'ID', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'piso', label: 'Piso/Sala', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'cama', label: 'Cama', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'clase', label: 'Clase', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'privado', label: 'Privado', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      listaTablaProductos: [],
      textButtonFormProd: 'AgregarProducto',
      tabs: [
        {
          title: 'Servicio', active: true, disabled: false,
        },
        {
          title: 'Producto', active: false, disabled: false,
        },
        {
          title: 'Habitacion/Cama', active: false, disabled: false,
        },
      ],
      isArchivoActivo: false,
      isButtonDisabled: false,
      datosHabitacion: [
        {
          numero: '',
          id: '1',
          piso: 'PS1:Primer Piso/ Sala 1',
          cama: 'CM1:Cama A',
          clase: 'CL1:General',
          privado: '800',
        },
        {
          numero: '',
          id: '2',
          piso: 'PS2:Primer Piso/ Sala 1',
          cama: 'CM2:Cama A',
          clase: 'CL2:General',
          privado: '900',
        },
      ],
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    /* eslint-disable prefer-destructuring */
    this.paisSelected = this.paises[0];
    this.paisSelected2 = this.paises[0];
    this.codigoPais = this.paises[0].codigo_telefono;
    this.codigoPais2 = this.paises[0].codigo_telefono;
    this.listaTabla = [];
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const response = await axios.get('/clinic/insurer/create');
        const CATEGORIAS_ASEGURADORA = response.data.data.categoria_aseguradoras;
        const { paises } = response.data.data;
        const { clientes } = response.data.data;
        const services = response.data.data.tipo_servicios;
        const { situaciones } = response.data.data;
        this.cargarPaises(paises);
        this.clientes = clientes;
        this.categoriaAseguradoras = CATEGORIAS_ASEGURADORA;
        this.cargarServicios(services);
        const products = [{
          numero: 1,
          editar: 'Editar',
          eliminar: 'Eliminar',
          codigo: 'A123',
          producto: 'Producto 1',
          subgrupo: '80%',
          coa_seguro: '50%',
          seguro: '80',
          total: '100',
          estado: 'Activo',
        },
        {
          numero: 2,
          editar: 'Editar',
          eliminar: 'Eliminar',
          codigo: 'B456',
          producto: 'Producto 2',
          subgrupo: '50%',
          coa_seguro: '30%',
          seguro: '80',
          total: '200',
          estado: 'Inactivo',
        },
        ];
        this.listaTablaProductos = products;
        this.cargarProductos(products);
        this.cargarSituaciones(situaciones);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async persistirFormulario(request) {
      this.buttonDisabled = true;
      try {
        const response = await axios.post('/clinic/insurer', request);
        util.showNotify(response.data.message, 'success');
        this.redirect({ name: 'aseguradora-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        this.buttonDisabled = false;
      }
    },
    guardarAseguradora() {
      if (this.clienteSelected === null) {
        util.showNotify('Elija un Cliente ', 'warn');
        return;
      }
      if (this.listaTabla.length === 0) {
        util.showNotify('Debe agregar por lo menos 1 Servicio', 'warn');
        return;
      }
      const contactos = [];
      if (this.contacto1 !== '') {
        contactos.push({
          nombre: this.contacto1,
          telefono: this.celular1,
          pais_id: this.paisSelected.id,
        });
      }
      if (this.contacto2 !== '') {
        contactos.push({
          nombre: this.contacto2,
          telefono: this.celular2,
          pais_id: this.paisSelected2.id,
        });
      }
      const aseguradoraServicios = [];
      this.listaTabla.forEach((item) => {
        const status = item.estado ? 'true' : 'false';
        const obj = {
          tipo_servicio_id: item.id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          comentario: null,
          coa_seguro: item.coa_seguro,
          seguro: item.seguro,
          situacion_id: item.situacion_id,
          estado: status,
        };
        aseguradoraServicios.push(obj);
      });
      const request = {
        aseguradora: {
          nit: this.clienteSelected.nit,
          persona_id: this.clienteSelected.id,
        },
        contactos,
        aseguradora_servicios: aseguradoraServicios,
      };
      this.persistirFormulario(request);
    },
    cargarPaises(lista) {
      this.paises = lista;
    },
    cargarServicios(lista) {
      this.servicios = lista;
    },
    cargarProductos(lista) {
      this.productos = lista;
    },
    cargarSituaciones(lista) {
      this.situaciones = lista;
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    setCodigoPais2() {
      this.codigoPais2 = this.paisSelected2.codigo_telefono;
    },
    resetearInputServicio() {
      this.serviciosSelected = null;
    },
    agregarModificarItem() {
      if (this.situaciones === null) {
        return;
      }
      if (this.serviciosSelected === null) {
        util.showNotify('Elije un servicio', 'warn');
        return;
      }
      if (this.categoriaSelected === null) {
        util.showNotify('Elije una categoria', 'warn');
        return;
      }
      if (this.modoEdicionTabla) {
        this.modificarItem();
      } else {
        this.agregarItem();
      }
    },
    agregarItem() {
      const LISTA_VERIFICACION = this.listaTabla.filter((el) => (
        el.id === this.serviciosSelected.id
        && el.categoria_aseguradora_id === this.categoriaSelected.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const item = {
        id: this.serviciosSelected.id,
        servicio: this.serviciosSelected.nombre,
        precio_1: this.serviciosSelected.precio_1,
        categoria_aseguradora_id: this.categoriaSelected.id,
        categoria: this.categoriaSelected.nombre,
        coa_seguro: this.coaseguro,
        seguro: this.seguro,
        total: this.coaseguro + this.seguro,
        situacion: this.situaciones[1].nombre,
        situacion_id: this.situaciones[1].id,
        estado: true,
        _rowVariant: '',
      };
      this.listaTabla.push(item);
      this.resetearInputServicio();
      this.categoriaSelected = null;
      this.coaseguro = 0;
      this.seguro = 0;
    },
    modificarItem() {
      const LISTA_TEMP = this.listaTabla.filter((el) => (
        el.id !== this.selectedId
        || el.categoria_aseguradora_id !== this.selectedCategoryId
      ));
      const LISTA_VERIFICACION = LISTA_TEMP.filter((el) => (
        el.id === this.serviciosSelected.id
        && el.categoria_aseguradora_id === this.categoriaSelected.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const INDEX = this.listaTabla.findIndex((el) => (
        el.id === this.selectedId
        && el.categoria_aseguradora_id === this.selectedCategoryId
      ));
      this.listaTabla[INDEX].id = this.serviciosSelected.id;
      this.listaTabla[INDEX].servicio = this.serviciosSelected.nombre;
      this.listaTabla[INDEX].precio_1 = this.serviciosSelected.precio_1;
      this.listaTabla[INDEX].categoria_aseguradora_id = this.categoriaSelected.id;
      this.listaTabla[INDEX].categoria = this.categoriaSelected.nombre;
      this.listaTabla[INDEX].coa_seguro = this.coaseguro;
      this.listaTabla[INDEX].seguro = this.seguro;
      this.listaTabla[INDEX].total = this.coaseguro + this.seguro;
      this.resetearInputServicio();
      this.categoriaSelected = null;
      this.coaseguro = 0;
      this.seguro = 0;
      this.selectedId = null;
      this.selectedCategoryId = null;
      this.desactivarModoEdicion();
    },
    activarModoEdicion() {
      this.modoEdicionTabla = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicionTabla = false;
      this.textButtonForm = 'Agregar';
    },
    onClickEditarIcon(item) {
      this.activarModoEdicion();
      this.selectedId = item.id;
      this.selectedCategoryId = item.categoria_aseguradora_id;
      this.serviciosSelected = {
        id: item.id,
        nombre: item.servicio,
        precio_1: item.precio_1,
      };
      this.categoriaSelected = {
        id: item.categoria_aseguradora_id,
        nombre: item.categoria,
      };
      this.coaseguro = item.coa_seguro;
      this.seguro = item.seguro;
    },
    onClickEliminarIcon(items) {
      if (this.modoEdicionTabla) {
        this.selectedId = null;
        this.selectedCategoryId = null;
        this.serviciosSelected = null;
        this.categoriaSelected = null;
        this.coaseguro = 0;
        this.seguro = 0;
        this.desactivarModoEdicion();
      }
      this.listaTabla.splice(items.index, 1);
    },
    modificarFila(data) {
      /* eslint-disable no-param-reassign */
      /* eslint-disable no-underscore-dangle */
      if (this.situaciones === null) {
        return;
      }
      if (data.item.estado) {
        data.item._rowVariant = '';
        data.item.situacion = this.situaciones[1].nombre;
        data.item.situacion_id = this.situaciones[1].id;
      } else {
        data.item._rowVariant = 'light';
        data.item.situacion = this.situaciones[0].nombre;
        data.item.situacion_id = this.situaciones[0].id;
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular1() {
      if (this.celular1 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular1)) {
        this.celular1 = '';
      }
    },
    reValidarCelular2() {
      if (this.celular2 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular2)) {
        this.celular2 = '';
      }
    },
    agregarItemProducto() {
    },
    activarModoEdicionProducto() {
      this.modoEdicionTablaProducto = true;
      this.textButtonFormProd = 'ModificarProducto';
    },
    desactivarModoEdicionProducto() {
      this.modoEdicionTabla = false;
      this.textButtonFormProd = 'AgregarProducto';
    },
    agregarModificarItemProducto() {
    },
    toggleArchivoActivo() {
      this.isArchivoActivo = !this.isArchivoActivo;
    },
    openFileDialog() {
      const input = this.$refs.fileInput;
      if (input && input.click) {
        input.click();
      } else {
        console.error('Referencia a fileInput no válida');
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      console.log('Archivo seleccionado:', file ? file.name : 'Ninguno');
    },
  },
  computed: {
    nit() {
      if (this.clienteSelected !== null) {
        return this.clienteSelected.nit;
      }
      return '';
    },
  },
};
</script>

<style>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.icon-container {
  position: relative;
  display: inline-block;
}
.info-message {
  display: none;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
  background-color: rgba(36, 33, 33, 0.7);
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: center;
  z-index: 1;
}
.visible {
  display: block;
}
.green{
  background-color: #76FF7A;
  color: white;
}
.nav-tabs .nav-item .nav-link:not(.disabled) {
  color: orange;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
